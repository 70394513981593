
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz,
        },
    })

    // *****************************************************************************************************************
    export default class MiscGameplay extends Vue {
        // Fields ------------------------------------------------------------------------------------------------------

        // Methods -----------------------------------------------------------------------------------------------------

        // Data function -----------------------------------------------------------------------------------------------
        public data() {
            return {
                engineIconPathAndName:
                    require("@/assets/images/icon_engine.png"),

                gameProgrammingPatternsQuiz01:
                    require("@/assets/quizzes/game-programming-patterns-quiz.json"),

            };
        }
    }
